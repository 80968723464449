"use client";
import React from "react";
import styled from "styled-components";
import Link from "next/link";
import { Black, hover, text, Transition } from "@/styles/globalStyleVars";
import arrow from "@/public/images/static/arrow-right.svg";
import Image from "next/image";

const Button = ({
  onSubmit,
  text,
  src,
  img,
  hoverImg,
  fontSize,
  fontWeight,
  color,
  letterSpacing,
  lineHeight,
  margin,
  background,
  borderRadius,
  border,
  width,
  height,
  hoverBackground,
  target,
  borderColor,
  hoverColor,
  icon,
  marginSm,
  onClick,
  className,
  iconBackground,
  lineColor,
  noAnim,
}) => {
  return (
    <StyledBtn
      onClick={onClick}
      className={`${className ? className : null} dc-btn ${
        !noAnim ? "fade-up" : null
      } `}
      fontSize={fontSize}
      fontWeight={fontWeight}
      color={color}
      background={background}
      lineHeight={lineHeight}
      letterSpacing={letterSpacing}
      margin={margin}
      border={border}
      img={img}
      borderRadius={borderRadius}
      width={width}
      hoverImg={hoverImg}
      hoverBackground={hoverBackground}
      height={height}
      borderColor={borderColor}
      target={target}
      hoverColor={hoverColor}
      onSubmit={onSubmit}
      icon={icon}
      marginSm={marginSm}
      iconBackground={iconBackground}
      lineColor={lineColor}
    >
      {src ? (
        <Link  prefetch={true} href={src || "/"}>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="12"
              viewBox="0 0 12 12"
            >
              <g
                id="Group_25659"
                data-name="Group 25659"
                transform="translate(-307 -515)"
              >
                <g id="Cross" transform="translate(-2.5 -1.5)">
                  <line
                    id="Line_12426"
                    data-name="Line 12426"
                    y2="10"
                    transform="translate(315.5 517.5)"
                    fill="none"
                    stroke="#fff"
                    strokeLinecap="round"
                    strokeWidth="2"
                  />
                  <line
                    id="Line_12427"
                    data-name="Line 12427"
                    y2="10"
                    transform="translate(320.5 522.5) rotate(90)"
                    fill="none"
                    stroke="#fff"
                    strokeLinecap="round"
                    strokeWidth="2"
                  />
                </g>
              </g>
            </svg>
          </span>
          {text}
        </Link>
      ) : (
        <a target={target || "_self"}>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="12"
              viewBox="0 0 12 12"
            >
              <g
                id="Group_25659"
                data-name="Group 25659"
                transform="translate(-307 -515)"
              >
                <g id="Cross" transform="translate(-2.5 -1.5)">
                  <line
                    id="Line_12426"
                    data-name="Line 12426"
                    y2="10"
                    transform="translate(315.5 517.5)"
                    fill="none"
                    stroke="#fff"
                    strokeLinecap="round"
                    strokeWidth="2"
                  />
                  <line
                    id="Line_12427"
                    data-name="Line 12427"
                    y2="10"
                    transform="translate(320.5 522.5) rotate(90)"
                    fill="none"
                    stroke="#fff"
                    strokeLinecap="round"
                    strokeWidth="2"
                  />
                </g>
              </g>
            </svg>
          </span>
          {text}
        </a>
      )}
    </StyledBtn>
  );
};

const StyledBtn = styled.div`
  &.dc-btn {
    margin: ${(props) => props.margin || "0"};
    width: ${(props) => props.width || "fit-content"};
    height: ${(props) => props.height || "30"}px;
    cursor: pointer;

    a {
      display: flex;
      width: fit-content;
      height: 100%;
      align-items: center;
      font-size: ${(props) => props.fontSize || "16"}px;
      font-weight: ${(props) => props.fontWeight || 500};
      margin: 0;
      line-height: ${(props) => props.lineHeight || "20"}px;
      background-color: ${(props) => props.background || `transparent`};
      position: relative;
      overflow: hidden;
      z-index: 0;
      transition: color 0.3s ease;
      box-sizing: border-box;
      border: ${(p) => p.border || "0"};
      color: ${(props) => props.color || `${hover}`};
      text-transform: capitalize;

      svg {
        transition: 0.6s ease;

        line {
          transition: stroke 0.3s ease;
        }
      }

      span {
        height: 30px;
        width: 30px;
        // background-color: ${hover};
        background-color: ${(props) => props.iconBackground || `#E62E43`};
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 20px;
        transition: background-color 0.4s ease;

        line {
          stroke: ${(props) => props.lineColor || `#FFF`};
        }
      }

      &:hover {
        line {
          stroke: #fff;
        }

        span {
          background-color: ${(p) => p.hoverColor || text} !important;
        }

        color: ${(p) => p.hoverColor || text} !important;

        svg {
          transform: rotate(180deg);
        }
      }

      &:focus {
        color: #222222;
      }
    }

    @media (max-width: 600px) {
      ${(p) => (p.marginSm ? `margin:${p.marginSm}` : "")}
    }
  }
`;

export default Button;
